import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Text } from 'assets/components/text';
import { ScreenContainer } from 'assets/layout';
import { useAppStateStore } from '../../store/app-store';
import { Icon } from 'assets/components/icon';
import { LocationIcon, NewMessageIcon } from 'assets/icons';
import { Button } from 'assets/components/button';
import { getSpacing, makeStyles, useTheme } from 'assets/theme';
import { NewMessageHandler } from './MessageProps';
import { NewMessage } from './NewMessage';
import { getText } from 'assets/localization/localization';
import { useMessagesState } from './messages-store';
import { setShowMessageModal } from './messages-actions';

export const MessagesEmptyState = (props: {
  onMessageCreate: () => Promise<void>;
}) => {
  const { pharmacyName } = useAppStateStore();
  const theme = useTheme();
  const newMessageRef = React.useRef<NewMessageHandler>(null);
  const styles = useStyles();
  const { showModal } = useMessagesState();

  useEffect(() => {
    if (showModal) {
      handleShowNewMessageModal();
    }
  }, [showModal]);

  const handleShowNewMessageModal = () => {
    newMessageRef.current?.show();
    setShowMessageModal(false);
  };

  return (
    <View style={styles.container}>
      <ScreenContainer>
        <View style={styles.locationContainer}>
          <Text style={styles.label}>{getText('you-are-chatting-with')}</Text>
          <View style={styles.pharmacyNameContainer}>
            <Icon
              icon={LocationIcon}
              size={16}
              color={theme.palette.gray[500]}
            />
            <Text style={styles.pharmacyName}>{pharmacyName}</Text>
          </View>
        </View>
        <NewMessage
          ref={newMessageRef}
          onMessageCreate={props.onMessageCreate}
        />
        <View style={styles.emptyStateContainer}>
          <Text style={styles.heading}>{getText('no-messages')}</Text>
          <Text style={styles.subheading}>
            {getText('messages-have-a-question')}
          </Text>
          <Button
            hierarchy={'primary'}
            logger={{
              id: 'new-message-modal-button-empty-state',
              data: undefined,
            }}
            icon={NewMessageIcon}
            onPress={handleShowNewMessageModal}
          >
            {getText('new-message')}
          </Button>
        </View>
      </ScreenContainer>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
  },
  label: {
    color: theme.palette.gray[500],
    marginBottom: theme.getSpacing(0.5),
  },
  locationContainer: {
    marginTop: theme.getSpacing(2),
    marginLeft: theme.getSpacing(1),
  },
  emptyStateContainer: {
    flex: 1,
    alignSelf: 'center',
    justifyContent: 'center',
    width: '50%',
  },
  heading: {
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 18,
  },
  subheading: {
    paddingTop: getSpacing(3),
    paddingBottom: getSpacing(3),
    textAlign: 'center',
    fontSize: 14,
    fontWeight: '400',
    color: theme.palette.gray[700],
  },
  pharmacyNameContainer: {
    flexDirection: 'row',
  },
  pharmacyName: {
    color: theme.palette.gray[500],
  },
}));
