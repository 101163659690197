import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { getText } from 'assets/localization/localization';
import { makeStyles } from 'assets/theme';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useTheme } from 'react-native-paper';
import { Modal } from 'assets/components/modal';
import { TouchableOpacity, View, Image } from 'react-native';
import { Text } from 'assets/components/text';
import {
  PatientUnderCareForm,
  ResourceItem,
  findAllergiesById,
} from '../../patient-actions';
import { UseFormReturn, useForm } from 'react-hook-form';
import {
  PatientUnderCareEditDetailsEnum,
  usePatientUnderCareState,
} from '../../patient-store';
import { EditPersonalInformation } from '../manage-people-under-care-details/EditPersonalInformation';
import { EditMedicalInformation } from '../manage-people-under-care-details/EditMedicalInformation';
import { PersonalInformationComponent } from '../account-information/PersonalInformationComponent';
import medicationService from '../../../../../api/medication-service';
export const PersonalInfoModal: FunctionComponent<
  PropsWithChildren<{
    setVisibility: (val: boolean) => void;
    handleRemovePatientUnderCare: (record?: RecordUnderCareDto) => void;
    record?: RecordUnderCareDto;
    showModal: boolean;
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    viewMode: boolean;
    relationshipProps?: Array<{ label: string; value: string }>;
    handleSave: (updatePR: UpdatePatientRecordDto) => void;
    onEditPress: (
      editDetail: PatientUnderCareEditDetailsEnum,
      methods: UseFormReturn<PatientUnderCareForm>,
    ) => void;
    whichDetailsToEdit: (
      editType: PatientUnderCareEditDetailsEnum.PersonalInfo,
    ) => void;
    editDetails: PatientUnderCareEditDetailsEnum;
    form: UseFormReturn<PatientUnderCareForm>;
    title: string;
  }>
> = ({
  showModal,
  setVisibility,
  handleRemovePatientUnderCare,
  record,
  patientRecord,
  changeViewMode,
  viewMode,
  relationshipProps,
  handleSave,
  onEditPress,
  whichDetailsToEdit,
  editDetails,
  form,
  title,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error } = usePatientUnderCareState();
  const address = patientRecord.address
    ? `${patientRecord.address.address1} ${patientRecord.address.address2} ${patientRecord.address.city} ${patientRecord.address.state} ${patientRecord.address.postal_code} ${patientRecord.address.country}`.trim()
    : getText('none');
  const [allergies, setAllergies] = useState<ResourceItem[]>();

  useEffect(() => {
    void (async () => {
      const allAllergies = await findAllergiesById(patientRecord.allergies);
      setAllergies(allAllergies);
    })();
  }, [patientRecord.allergies, patientRecord.medical_conditions]);

  return (
    <Modal
      show={showModal}
      title={title}
      okButtonProps={{
        text: getText('remove'),
        hierarchy: 'destructive-secondary',
        onPress: () => handleRemovePatientUnderCare(record),
        logger: { id: 'remove-patient-access-modal-send-button' },
      }}
      cancelButtonProps={{
        text: getText('cancel'),
        hierarchy: 'secondary-gray',
        onPress: () => setVisibility(false),
        logger: { id: 'cancel-patient-access-modal-send-button' },
      }}
      isScrollable={true}
    >
      <View style={styles.container}>
        <View>
          {!viewMode ? (
            <View>
              <PersonalInformationComponent
                patientRecord={patientRecord}
                onEditPress={() => {
                  onEditPress(
                    PatientUnderCareEditDetailsEnum.PersonalInfo,
                    form,
                  );
                }}
              ></PersonalInformationComponent>
              <View style={styles.requestTextContainer}>
                <Text style={styles.title}>
                  {getText('medical-info').toUpperCase()}
                </Text>
                <TouchableOpacity
                  style={styles.textContainer}
                  onPress={() =>
                    onEditPress(
                      PatientUnderCareEditDetailsEnum.MedicalInfo,
                      form,
                    )
                  }
                >
                  <Text
                    style={[styles.pressableTextRevoke, styles.leftSpacing]}
                  >
                    {getText('edit')}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>{getText('allergies')}</Text>
                <Text style={styles.boldSubtitle}>
                  {allergies && allergies.length > 0
                    ? allergies.map((v) => v.text).join(', ')
                    : getText('none')}
                </Text>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>
                  {getText('medical-conditions')}
                </Text>
                <Text style={styles.boldSubtitle}>
                  {patientRecord.medical_conditions &&
                  patientRecord.medical_conditions.length > 0
                    ? patientRecord.medical_conditions.map((v) => v).join(', ')
                    : getText('none')}
                </Text>
              </View>
              <View style={{ marginBottom: theme.getSpacing(3) }}>
                <Text style={styles.subtitle}>
                  {getText('easy-open-bottle-caps')}
                </Text>
                <Text style={styles.boldSubtitle}>
                  {patientRecord.prefers_easy_open_bottle_caps
                    ? getText('yes')
                    : getText('no')}
                </Text>
              </View>
            </View>
          ) : PatientUnderCareEditDetailsEnum.PersonalInfo === editDetails ? (
            <EditPersonalInformation
              changeViewMode={changeViewMode}
              relationshipProps={relationshipProps}
              handleSave={handleSave}
              patientRecord={patientRecord}
              record={record}
              form={form}
            ></EditPersonalInformation>
          ) : PatientUnderCareEditDetailsEnum.MedicalInfo === editDetails ? (
            <EditMedicalInformation
              patientRecord={patientRecord}
              changeViewMode={changeViewMode}
              handleSave={handleSave}
              form={form}
            ></EditMedicalInformation>
          ) : (
            // TODO Insurance and Id info will come here - out of scope for BLUES-2677
            <></>
          )}
        </View>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  container: {},
  title: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontWeight: '800',
  },
  subtitle: {
    fontWeight: '400',
    color: theme.palette.gray[600],
    fontSize: 14,
    lineHeight: 20,
  },
  boldSubtitle: {
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 28,
    color: theme.palette.gray[900],
  },
  requestTextContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.getSpacing(3),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: '500',
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.getSpacing(1),
  },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  subTitle: {
    alignSelf: 'flex-start',
    marginBottom: 8,
    fontSize: 16,
  },
  row: {
    alignContent: 'stretch',
  },
  selectStyles: {
    display: 'flex',
    alignItems: 'stretch',
  },
}));
