import { Form } from 'assets/layout';
import { makeStyles, useTheme } from 'assets/theme';
import { Text } from 'assets/components/text';
import { View } from 'react-native';
import {
  MedicalForm,
  ResourceItem,
  findAllergiesById,
} from '../../patient-actions';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from 'assets/components/typeahead-with-tags';
import medicationService from '../../../../../api/medication-service';
import { UseFormReturn } from 'react-hook-form';
import { LoadingIndicator } from 'assets/components/loading-indicator';
import { getText } from 'assets/localization/localization';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import _ from 'cypress/types/lodash';

export enum MedicalInfoType {
  MedicalInfo = 'medicalInfo',
}

export const MedicalInfoConfig: Record<
  MedicalInfoType,
  {
    titleAllergies: string;
    allergiesHintMessage: string;
    titleMedicalConditions: string;
    medicalConditionsHintMessage: string;
  }
> = {
  [MedicalInfoType.MedicalInfo]: {
    titleAllergies: getText('allergies'),
    allergiesHintMessage: getText('your-allergies'),
    titleMedicalConditions: 'Medical conditions', //TODO localize
    medicalConditionsHintMessage: getText('medical-conditions'), //TODO will be added in BLUES-2838
  },
};

export const MedicalInfoComponent: FunctionComponent<
  PropsWithChildren<{
    allergyIds?: string[];
    medicalConditionsIds?: string[];
    medicalForm: UseFormReturn<MedicalForm>;
    medicalInfoType: MedicalInfoType;
  }>
> = ({ medicalForm, allergyIds, medicalConditionsIds, medicalInfoType }) => {
  const {
    titleAllergies,
    allergiesHintMessage,
    titleMedicalConditions,
    medicalConditionsHintMessage,
  } = MedicalInfoConfig[medicalInfoType];
  const theme = useTheme();
  const styles = useStyles();
  const [allergies, setAllergies] = useState<ResourceItem[]>();
  const [allergyTags, setAllergyTags] = useState<TypeaheadBaseItem[]>();
  const [medicalConditions, setMedicalConditions] = useState<ResourceItem[]>();

  const allergiesFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'allergies',
    typeaheadWithTagsProps: {
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return medicationService.allergySearch(searchTerm);
      },
      hintMessage: allergiesHintMessage,
      explicitEmptyModeProps: {
        explicitNotEmptyLabel: getText('known-allergies'),
        explicitEmptyLabel: getText('no-known-allergies'),
        emptyValue: { text: getText('no-allergies-2'), value: 'no_allergies' },
      },
      defaultValue: allergies,
      tags: allergyTags,
    },
  };

  useEffect(() => {
    void (async () => {
      if (allergyIds) {
        const allergies = await findAllergiesById(allergyIds);
        setAllergies(allergies);
      }
      if (allergies) medicalForm.setValue('allergies', allergies);
    })();
  }, [allergies?.values]);

  if (allergyIds === undefined) {
    return <LoadingIndicator></LoadingIndicator>;
  } else {
    return (
      <View>
        <Form methods={medicalForm}>
          <View>
            <Text>{titleAllergies + ' *'}</Text>
            <View style={styles.marginTop}>
              <TypeaheadWithTagsField {...allergiesFieldProps} />
            </View>
          </View>
        </Form>
      </View>
    );
  }
};

const useStyles = makeStyles((theme) => ({
  marginTop: {
    marginTop: theme.getSpacing(1),
  },
}));
