import React, {
  useEffect,
  useState,
  FunctionComponent,
  PropsWithChildren,
} from 'react';
import { View } from 'react-native';
import { CheckboxInput } from '../checkbox';
import { Tag } from '../tag';
import { Typeahead } from '../typeahead';
import { TypeaheadBaseItem, TypeaheadProps } from '../typeahead/types';
import { uniqueWithMap } from '../typeahead/utils';
import { Text } from '../text';
import { makeStyles, useTheme } from '../../theme';
import { RadioButtonGroupField } from '../radio-button-group';
import { useFormContext } from 'react-hook-form';
import { getText } from '../../localization/localization';

export interface TypeaheadWithTagsProps
  extends TypeaheadProps<string | TypeaheadBaseItem> {
  tags?: Array<string | TypeaheadBaseItem>;

  explicitEmptyModeProps?: {
    explicitEmptyLabel: string;
    explicitNotEmptyLabel: string;
    emptyValue: string | TypeaheadBaseItem;
  };
}

enum ExplicitEmptyState {
  Empty = 'empty',
  NotEmpty = 'not_empty',
}

export const TypeaheadWithTags: FunctionComponent<
  PropsWithChildren<TypeaheadWithTagsProps>
> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  // in case it is not supplied from the developer lets fallback to the default value
  const getOptionValueFallback = function (
    option: string | TypeaheadBaseItem,
  ): string {
    if (typeof option === 'string') {
      return option;
    }
    return option.value;
  };
  const getOptionValue = props.getOptionValue || getOptionValueFallback;
  // in case it is not supplied from the developer lets fallback to the default value
  const getOptionTextFallback = (option: any) => option.text ?? option;
  const getOptionText = props.getOptionText || getOptionTextFallback;

  const [selectedValues, setSelectedValues] = useState(
    props.defaultValue ?? [],
  );
  const [explicitEmptyState, setExplicitEmptyState] = useState<
    ExplicitEmptyState | undefined
  >(undefined);
  const formContext = useFormContext();
  const { getValues, setValue } = formContext;

  // undefined -> undefined
  // [] (truthy) -> undefined
  // ['no_known_allergies'] -> checkbox explicity empty true
  // ['1231231-1231] -> checkbox explicit empty false

  function filterOutEmptyOption(
    items: Array<string | TypeaheadBaseItem>,
  ): Array<string | TypeaheadBaseItem> {
    return items.filter((x) =>
      !!props.explicitEmptyModeProps
        ? getOptionValue(x) !==
          getOptionValue(props.explicitEmptyModeProps.emptyValue)
        : true,
    );
  }

  useEffect(() => {
    if (props.defaultValue === undefined || props.defaultValue.length === 0)
      return; // assumes checkbox is undefined

    if (!!props.explicitEmptyModeProps) {
      const isDefaultValueExplicitlyEmpty: boolean =
        props.defaultValue.length === 1 &&
        getOptionValue(props.defaultValue[0]) ===
          getOptionValue(props.explicitEmptyModeProps.emptyValue);
      if (isDefaultValueExplicitlyEmpty) {
        setExplicitEmptyState(ExplicitEmptyState.Empty);
      } else {
        setSelectedValues(props.defaultValue);
        setValue('isSelectable', ExplicitEmptyState.NotEmpty);
        setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
      }
    }
  }, [props.defaultValue]);

  const onChanged = (items: (string | TypeaheadBaseItem)[]) => {
    let newItems = items;
    setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
    setSelectedValues(newItems);
    props.onChange?.(newItems);
  };

  const onSuggestionPressed = (value: string | TypeaheadBaseItem) => {
    let newSelectedValue: Array<any> = [];
    const currentValueExist = selectedValues?.filter(
      (x) => getOptionValue(x) === getOptionValue(value),
    );
    if (currentValueExist && currentValueExist?.length > 0) {
      // remove it from selected values
      newSelectedValue =
        selectedValues?.filter(
          (x) => getOptionValue(x) !== getOptionValue(value),
        ) || [];
    } else {
      newSelectedValue = !props.multiple
        ? [value]
        : (uniqueWithMap(
            [...(selectedValues || []), value],
            getOptionValue,
          ) as any[]);
    }

    if (!!props.explicitEmptyModeProps)
      newSelectedValue = filterOutEmptyOption(newSelectedValue);

    setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
    setValue('isSelectable', ExplicitEmptyState.NotEmpty);
    setSelectedValues(newSelectedValue);
    props.onChange?.(newSelectedValue);
  };

  const handleExplicitEmptyRadioButtonPress = (item: any) => {
    if (!props.explicitEmptyModeProps) {
      throw new Error('Expected to be in explicit empty mode');
    }
    switch (item.target.value) {
      case ExplicitEmptyState.Empty: {
        // Do nothing if we are already in this state
        setExplicitEmptyState(ExplicitEmptyState.Empty);
        const newValue =
          explicitEmptyState === ExplicitEmptyState.Empty ? [] : [];
        setSelectedValues([]);
        props.onChange?.(newValue);
        break;
      }
      case ExplicitEmptyState.NotEmpty: {
        // Do nothing if we are already in this state
        if (!explicitEmptyState) return;
        setExplicitEmptyState(ExplicitEmptyState.NotEmpty);
        const newValue = filterOutEmptyOption(selectedValues);
        setSelectedValues(newValue);
        props.onChange?.(newValue);
        break;
      }
      default:
        throw new Error('Unsupported radio button');
    }
  };

  return (
    <View>
      {props.label && <Text style={styles.label}>{props.label}</Text>}
      {props.explicitEmptyModeProps && (
        <View style={{ marginBottom: theme.getSpacing(1) }}>
          <RadioButtonGroupField
            name="isSelectable"
            values={[
              {
                text: props.explicitEmptyModeProps.explicitEmptyLabel,
                value: ExplicitEmptyState.Empty,
              },
              {
                text: props.explicitEmptyModeProps.explicitNotEmptyLabel,
                value: ExplicitEmptyState.NotEmpty,
              },
            ]}
            rules={{
              onChange: handleExplicitEmptyRadioButtonPress,
            }}
          />
        </View>
      )}
      <Typeahead
        {...props}
        defaultValue={selectedValues?.filter((x) =>
          // don't display the explicit empty value
          !!props.explicitEmptyModeProps
            ? getOptionValue(x) !==
              getOptionValue(props.explicitEmptyModeProps.emptyValue)
            : true,
        )}
        // forcing empty value to not be shown
        // because will be considered the checkbox above
        // emptyValue={undefined}
        // same for the label as the comment above
        label={undefined}
        onChange={onChanged}
        disabled={
          props.disabled || explicitEmptyState === ExplicitEmptyState.Empty
        }
      />
      <View
        style={{
          flexDirection: 'row',
          flex: 1,
          flexWrap: 'wrap',
        }}
      >
        {props.tags?.map((x, i) => (
          <Tag
            key={i}
            onPress={() => onSuggestionPressed(x)}
            label={(x as TypeaheadBaseItem).text ?? (x as string)}
            selected={
              !!selectedValues?.find(
                (s) => getOptionValue(s) === getOptionValue(x),
              )
            }
            style={{
              height: 28,
              marginRight: theme.getSpacing(1),
              marginTop: theme.getSpacing(1),
            }}
            disabled={
              props.disabled || explicitEmptyState === ExplicitEmptyState.Empty
            }
            textProps={{ ellipsizeMode: 'tail', numberOfLines: 1 }}
          />
        ))}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  label: {
    marginLeft: theme.getSpacing(0.5),
    marginBottom: theme.getSpacing(1),
  },
}));
