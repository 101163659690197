import { AxiosRequestConfig } from 'axios';
import { BaseApiConfiguration, BaseApiService } from 'assets/core/api';
import { MEDICATION_SERVICE_BASE_URL } from '../common/constants';

import {
  AllergyDto,
  DefaultApi as MedicationServiceClient,
} from '@digitalpharmacist/medication-service-client-axios';
import { AXIOS_DEFAULT_REQUEST_CONFIG } from '../common/axios-default-request-config';
import { TypeaheadBaseItem } from 'assets/components/typeahead/types';
import { ResourceItem } from '../screens/refill/refill-store';

export interface IMedicationService {
  allergySearch(searchTerm: string): Promise<TypeaheadBaseItem[]>;
  allergyFind(id: string): Promise<ResourceItem[]>;
}

export class MedicationService
  extends BaseApiService
  implements IMedicationService
{
  private medicationServiceClient: MedicationServiceClient;
  public readonly topAllergiesHints = [
    'Penicillin',
    'Sulfa drugs',
    'Aspirin',
    'Ibuprofen',
    'Latex',
    'Eggs',
    'Shellfish',
  ];

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration,
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.medicationServiceClient = new MedicationServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance,
    );
  }

  async allergySearch(searchTerm: string): Promise<TypeaheadBaseItem[]> {
    const { data } =
      await this.medicationServiceClient.allergySearch(searchTerm);
    return data.map((allergy) => ({
      text: allergy.allergy,
      value: allergy.id,
    }));
  }

  async allergyFind(id: string): Promise<ResourceItem[]> {
    const { data } = await this.medicationServiceClient.allergyFind(id);
    return data.map((allergy) => ({
      text: allergy.allergy,
      value: allergy.id,
    }));
  }

  async conditionSearch(searchTerm: string): Promise<TypeaheadBaseItem[]> {
    const { data } =
      await this.medicationServiceClient.conditionSearch(searchTerm);
    return data.map((condition) => ({
      text: condition.condition,
      value: condition.id,
    }));
  }

  async conditionFind(id: string): Promise<ResourceItem[]> {
    const { data } = await this.medicationServiceClient.conditionFind(id);
    return data.map((condition) => ({
      text: condition.condition,
      value: condition.id,
    }));
  }
}

export default new MedicationService(
  MEDICATION_SERVICE_BASE_URL,
  AXIOS_DEFAULT_REQUEST_CONFIG,
  false,
);
