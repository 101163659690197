import { Form } from 'assets/layout';
import { getText } from 'assets/localization/localization';
import { makeStyles, useTheme } from 'assets/theme';
import { View } from 'react-native';
import { RadioButtonGroupField } from 'assets/components/radio-button-group';
import { Button } from 'assets/components/button';
import { UseFormReturn, useForm } from 'react-hook-form';
import {
  MedicalForm,
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from '../../patient-actions';
import { usePatientUnderCareState } from '../../patient-store';
import {
  PatientRecordDto,
  RecordUnderCareDto,
  UpdatePatientRecordDto,
} from '@digitalpharmacist/patient-service-client-axios';
import { Text } from 'assets/components/text';
import { FunctionComponent, PropsWithChildren } from 'react';
import {
  TypeaheadWithTagsField,
  TypeaheadWithTagsFieldProps,
} from 'assets/components/typeahead-with-tags';
import refillService from '../../../../refill/refill-service';
import {
  MedicalInfoComponent,
  MedicalInfoType,
} from '../medical-info/MedicalInfoComponent';

export const EditMedicalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    record?: RecordUnderCareDto;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({ patientRecord, changeViewMode, handleSave, record, form }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error } = usePatientUnderCareState();
  const medicalForm = useForm<MedicalForm>({});

  const medicalConditionsFieldProps: TypeaheadWithTagsFieldProps = {
    name: 'medical_conditions',
    typeaheadWithTagsProps: {
      multiple: true,
      asyncOptions: async (searchTerm: string) => {
        if (!searchTerm) return Promise.resolve([]);
        return refillService.searchMedicalConditions(searchTerm);
      },
      hintMessage: getText('medical-conditions'),
      defaultValue: form.getValues().medical_conditions,
      tags: refillService.topMedicalConditionsHints,
    },
  };

  const handleSubmit = () => {
    form.setValue('allergies', medicalForm.getValues().allergies);
    updatePatientUnderCareForm(form.getValues());
    changeViewMode(false);
    const prefers_easy_open_bottle_caps_str =
      form.getValues().prefers_easy_open_bottle_caps_str;
    if (prefers_easy_open_bottle_caps_str === getText('yes')) {
      form.setValue('prefers_easy_open_bottle_caps', true);
    } else if (prefers_easy_open_bottle_caps_str === getText('no')) {
      form.setValue('prefers_easy_open_bottle_caps', false);
    }
    const updatePatientRecord: UpdatePatientRecordDto = {
      prefers_easy_open_bottle_caps:
        form.getValues().prefers_easy_open_bottle_caps,
      allergies:
        form.getValues().allergies?.find((v) => v.value !== 'no_allergies') &&
        form
          .getValues()
          .allergies?.map((v) => v.value)
          .filter((v) => v !== 'no_allergies'),
      medical_conditions: form
        .getValues()
        .medical_conditions?.map((v) => v.value),
    };
    handleSave(updatePatientRecord);
  };

  return (
    <View>
      <MedicalInfoComponent
        medicalInfoType={MedicalInfoType.MedicalInfo}
        medicalForm={medicalForm}
        allergyIds={patientRecord.allergies}
      ></MedicalInfoComponent>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TypeaheadWithTagsField {...medicalConditionsFieldProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
            <Text> {getText('would-like-easy-open-caps')}</Text>
            <RadioButtonGroupField
              name="prefers_easy_open_bottle_caps_str"
              testID={'easy-open-bottle-caps-test-id'}
              values={[
                {
                  text: getText('prefers-easy-open-caps'),
                  value: getText('yes'),
                },
                {
                  text: getText('prefers-safety-caps'),
                  value: getText('no'),
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            onPress={form.handleSubmit(handleSubmit)}
            hierarchy="primary"
            loading={status === 'loading'}
            logger={{ id: 'personal-info-save-button' }}
          >
            {getText('save')}
          </Button>
        </Form.Actions>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'stretch',
  },
  row: {
    alignContent: 'stretch',
  },
}));
